import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/index/index.vue')
  },
  {
    path:'/getUrl',
    component:() => import('../views/getUrl/index.vue')
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
